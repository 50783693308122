import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SearchRoom = () => {
  const { t } = useTranslation();
  const [roomName, setRoomName] = useState('');
  const [error, setError] = useState('');
  const [room, setRoom] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setRoom(null);

    const searchRoomUrl = `${process.env.REACT_APP_API_URL}/room/search`;
    console.log("Search Room URL:", searchRoomUrl);

    try {
      const response = await axios.post(searchRoomUrl, { room_name: roomName }, { withCredentials: true });
      if (response.status === 200) {
        setRoom(response.data);
      } else {
        setError(t('Room not found.'));
      }
    } catch (error) {
      console.error('Error searching room:', error);
      setError(t('Error searching room.'));
    }
  };

  const handleEnterRoom = () => {
    navigate(`/chat_room/${room.room_id}`);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-gray-900">{t('Search Room')}</h2>
        {error && <p className="text-red-500 text-center">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="roomName" className="block text-sm font-medium text-gray-700">{t('Room Name')}</label>
            <input
              type="text"
              id="roomName"
              value={roomName}
              onChange={(e) => setRoomName(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              placeholder={t('Room Name')}
              required
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full px-4 py-2 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {t('Search Room')}
            </button>
          </div>
        </form>
        {room && (
          <div className="mt-6 p-4 border rounded">
            <h2 className="text-xl font-semibold">{room.room_name}</h2>
            <p>Room ID: {room.room_id}</p>
            <button
              onClick={handleEnterRoom}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              {t('Enter Room')}
            </button>
          </div>
        )}
        <div>
          <button
            onClick={() => navigate('/user_panel')}
            className="w-full px-4 py-2 mt-4 text-white bg-gray-600 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            {t('User Panel')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchRoom;
