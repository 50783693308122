// src/components/ActiveRooms.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ActiveRooms = () => {
  const { t } = useTranslation();
  const [rooms, setRooms] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchActiveRooms = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/active_rooms`, { withCredentials: true });
        if (response.status === 200) {
          setRooms(response.data.rooms);
        }
      } catch (error) {
        console.error('Error fetching active rooms:', error);
        setError(t('Error fetching active rooms'));
      }
    };

    fetchActiveRooms();
  }, [t]);

  const handleRoomClick = (roomId) => {
    navigate(`/room_detail/${roomId}`);
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <button
          onClick={() => navigate('/user_panel')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {t('User Panel')}
        </button>
        <button
          onClick={() => navigate('/active_rooms')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
        >
          {t('Active Rooms')}
        </button>
        <button
          onClick={() => navigate('/inactive_rooms')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
        >
          {t('Inactive Rooms')}
        </button>
      </div>

      <h1 className="text-2xl font-bold mb-4">{t('Active Rooms')}</h1>
      {error && <p className="text-red-500">{error}</p>}
      <div className="space-y-4">
        {rooms.map((room) => (
          <div key={room.room_id} className="p-4 border rounded" onClick={() => handleRoomClick(room.room_id)}>
            <h2 className="text-xl font-semibold">{room.room_name}</h2>
            <p>{t('Created At')}: {new Date(room.date_subscribed).toLocaleString()}</p>
          </div>
        ))}
      </div>
    </div>
  );
}; 

export default ActiveRooms;
