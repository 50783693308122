// src/components/Register.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Register = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [passworda, setPassworda] = useState('');
  const [confirmPassworda, setConfirmPassworda] = useState('');
  const [passwordb, setPasswordb] = useState('');
  const [confirmPasswordb, setConfirmPasswordb] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (passworda !== confirmPassworda) {
      setError(t('Password A and Confirm Password A do not match.'));
      return;
    }

    if (passwordb !== confirmPasswordb) {
      setError(t('Password B and Confirm Password B do not match.'));
      return;
    }

    const registerUrl = `${process.env.REACT_APP_API_URL}/register`;
    console.log("Register URL:", registerUrl);

    try {
      const response = await axios.post(registerUrl, { username, passworda, passwordb }, { withCredentials: true });
      if (response.status === 201) {
        alert(response.data.message);
        navigate('/login');
      }
    } catch (error) {
      console.error('Error registering:', error);
      setError(t('Failed to register. Please try again.'));
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-gray-900">{t('Register')}</h2>
        {error && <p className="text-red-500 text-center">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">{t('Username')}</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              placeholder={t('Username')}
              required
            />
          </div>
          <div>
            <label htmlFor="passworda" className="block text-sm font-medium text-gray-700">{t('Password A')}</label>
            <input
              type="password"
              id="passworda"
              value={passworda}
              onChange={(e) => setPassworda(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              placeholder={t('Password A')}
              required
            />
          </div>
          <div>
            <label htmlFor="confirmPassworda" className="block text-sm font-medium text-gray-700">{t('Confirm Password A')}</label>
            <input
              type="password"
              id="confirmPassworda"
              value={confirmPassworda}
              onChange={(e) => setConfirmPassworda(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              placeholder={t('Confirm Password A')}
              required
            />
          </div>
          <div>
            <label htmlFor="passwordb" className="block text-sm font-medium text-gray-700">{t('Password B')}</label>
            <input
              type="password"
              id="passwordb"
              value={passwordb}
              onChange={(e) => setPasswordb(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              placeholder={t('Password B')}
              required
            />
          </div>
          <div>
            <label htmlFor="confirmPasswordb" className="block text-sm font-medium text-gray-700">{t('Confirm Password B')}</label>
            <input
              type="password"
              id="confirmPasswordb"
              value={confirmPasswordb}
              onChange={(e) => setConfirmPasswordb(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              placeholder={t('Confirm Password B')}
              required
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full px-4 py-2 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {t('Register Button')}
            </button>
          </div>
        </form>
        <div>
          <button
            onClick={() => navigate('/login')}
            className="w-full px-4 py-2 mt-4 text-white bg-gray-600 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            {t('Login')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Register;