import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "Buy Room": "Buy Room",
      "Room Name": "Room Name",
      "Room Password": "Room Password",
      "User Panel": "User Panel",
      "Failed to buy room. Please try again.": "Failed to buy room. Please try again.",
      "Buy Room Button": "Buy Room",
      "Chat Room": "Chat Room",
      "Leave": "Leave",
      "Online Users": "Online Users",
      "No messages": "No messages",
      "User": "User",
      "Type your message here...": "Type your message here...",
      "Send": "Send",
      "Hello,": "Hello,",
      "No rooms purchased yet": "No rooms purchased yet",
      "Enter Room": "Enter Room",
      "Error fetching user panel": "Error fetching user panel",
      "Logout": "Logout",
      "Error logging out": "Error logging out",
      "Login": "Login",
      "Username": "Username",
      "Password": "Password",
      "Login failed. Please check your credentials and try again.": "Login failed. Please check your credentials and try again.",
      "Register": "Register",
      "Register Button": "Register",
      "Total Rooms": "Total Rooms",
      "Active Rooms": "Active Rooms",
      "Inactive Rooms": "Inactive Rooms",
      "Countdown": "Countdown",
      "days": "days",
      "Inactive": "Inactive",
      "Search Room": "Search Room",
      "Waiting for transaction...Time": "Waiting for transaction...Time: {time}",
      "Payment not confirmed yet. Please wait or try again.": "Payment not confirmed yet. Please wait or try again.",
      "Order timeout. Please reorder.": "Order timeout. Please reorder.",
      "Enter Room and User Password": "Enter Room and User Password",
      "User Password": "User Password",
      "has entered the room": "has entered the room",
      "has left the room": "has left the room",
      "System": "System",
      "Invite": "Invite",
      "Copy": "Copy",
      "Invite link copied to clipboard!": "Invite link copied to clipboard!",
      "Invite Link": "Invite Link",
    }
  },
  zh: {
    translation: {
      "Buy Room": "买房间",
      "Room Name": "房间名称",
      "Room Password": "房间密码",
      "User Panel": "用户面板",
      "Failed to buy room. Please try again.": "购买房间失败，请重试。",
      "Buy Room Button": "买房间",
      "Chat Room": "聊天室",
      "Leave": "离开",
      "Online Users": "在线用户",
      "No messages": "没有消息",
      "User": "用户",
      "Type your message here...": "在这里输入您的消息...",
      "Send": "发送",
      "Hello,": "您好，",
      "No rooms purchased yet": "当前还未购买任何聊天室",
      "Enter Room": "进入房间",
      "Error fetching user panel": "获取用户面板时出错",
      "Logout": "退出登录",
      "Error logging out": "退出登录时出错",
      "Login": "登录",
      "Username": "用户名",
      "Password": "密码",
      "Login failed. Please check your credentials and try again.": "登录失败，请检查您的凭据并重试。",
      "Register": "注册",
      "Register Button": "注册",
      "Total Rooms": "总房间数",
      "Active Rooms": "已激活房间",
      "Inactive Rooms": "未激活房间",
      "Countdown": "倒数时间",
      "days": "天",
      "Inactive": "未激活",
      "Search Room": "搜索房间",
      "Waiting for transaction...Time": "等待交易... 剩余时间：{time}",
      "Payment not confirmed yet. Please wait or try again.": "支付尚未确认。请稍等或再试一次。",
      "Order timeout. Please reorder.": "订单超时。请重新下单。",
      "Enter Room and User Password": "输入房间和用户密码",
      "User Password": "用户密码",
      "has entered the room": "进入了聊天室",
      "has left the room": "离开了聊天室",
      "System": "系统",
      "Invite": "邀请",
      "Copy": "复制",
      "Invite link copied to clipboard!": "邀请链接已复制到剪贴板！",
      "Invite Link": "邀请链接",

    }
  },
  zh_TW: {
    translation: {
      "Buy Room": "買房間",
      "Room Name": "房間名稱",
      "Room Password": "房間密碼",
      "User Panel": "用戶面板",
      "Failed to buy room. Please try again.": "購買房間失敗，請重試。",
      "Buy Room Button": "買房間",
      "Chat Room": "聊天室",
      "Leave": "離開",
      "Online Users": "在線用戶",
      "No messages": "沒有消息",
      "User": "用戶",
      "Type your message here...": "在這裡輸入您的消息...",
      "Send": "發送",
      "Hello,": "您好，",
      "No rooms purchased yet": "當前還未購買任何聊天室",
      "Enter Room": "進入房間",
      "Error fetching user panel": "獲取用戶面板時出錯",
      "Logout": "退出登录",
      "Error logging out": "退出登录時出錯",
      "Login": "登錄",
      "Username": "用戶名",
      "Password": "密碼",
      "Login failed. Please check your credentials and try again.": "登錄失敗，請檢查您的憑據並重試。",
      "Register": "註冊",
      "Register Button": "註冊",
      "Total Rooms": "總房間數",
      "Active Rooms": "已激活房間",
      "Inactive Rooms": "未激活房間",
      "Countdown": "倒數時間",
      "days": "天",
      "Inactive": "未激活",
      "Search Room": "搜索房間",
      "Waiting for transaction...Time": "等待交易... 剩餘時間：{time}",
      "Payment not confirmed yet. Please wait or try again.": "支付尚未確認。請稍等或再試一次。",
      "Order timeout. Please reorder.": "訂單超時。請重新下單。",
      "Enter Room and Password": "輸入房間名稱和密碼",
      "User Password": "用戶密碼",
      "has entered the room": "進入了聊天室",
      "has left the room": "離開了聊天室",
      "System": "系統",
      "Invite": "邀請",
      "Copy": "複製",
      "Invite link copied to clipboard!": "邀請鏈接已複製到剪貼板！",
      "Invite Link": "邀請鏈接",
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: navigator.language,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
