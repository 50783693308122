// src/components/ChatRoom.js
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import initializeSocket from './WebSocketClient';
import EmojiPicker from 'emoji-picker-react';
import moment from 'moment-timezone';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const ChatRoom = () => {
  const [socket, setSocket] = useState(null);
  const { t } = useTranslation();
  const { roomId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [username, setUsername] = useState('');
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [roomPassword, setRoomPassword] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState('');
  const [roomName, setRoomName] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const messagesEndRef = useRef(null);

  const [images, setImages] = useState([]); // 存储所有可放大的图片链接
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const [inviteLink, setInviteLink] = useState('');
  const [showInviteLink, setShowInviteLink] = useState(false);

  const isImageUrl = (url) => {
    return /\.(jpeg|jpg|gif|png|bmp|webp)$/i.test(url);
  };

  useEffect(() => {
    console.log("ChatRoom component mounted");
    const sessionId = localStorage.getItem('session_id');
    console.log("Session ID from localStorage:", sessionId);
    if (!sessionId) {
      console.log("No session ID, redirecting to login");
      navigate('/login', { state: { from: location.pathname } });
    } else {
      // 每次进入聊天室时，重置认证状态
      // setIsAuthenticated(false);
      console.log("Initializing socket");
      const newSocket = initializeSocket();
      console.log("New socket object:", newSocket);
      setSocket(newSocket);

      return () => {
        console.log("ChatRoom component unmounting, disconnecting socket");
        if (newSocket) {
          newSocket.disconnect();
        }
      };
    }
  }, [navigate, location.pathname]);

  useEffect(() => {
    if (socket) {
      console.log("Setting up socket event listeners");

      socket.on('connect', () => {
        console.log('ChatRoom L63 Connected to WebSocket server');
      });

      socket.on('disconnect', (reason) => {
        console.log('L67 ChatRoom Disconnected from WebSocket server:', reason);
      });

      socket.on('message', (msg) => {
            console.log("L77 ChatRoom Received message:", msg);
            // 检查消息类型并处理相应的数据
            if (msg.type === 'message' && msg.data) {
                console.log("L73 Received message:", msg.data);
                setMessages((prevMessages) => [...prevMessages, msg.data]);
            }
        });

      socket.on('user_list', (users) => {
        console.log("L85 Received user list:", users); // 调试日志
        if (Array.isArray(users)) {
          setOnlineUsers(users.filter(user => typeof user === 'string'));
        }
      });
      
      socket.on('error', (error) => {
        console.error('WebSocket error:', error);
      });

      socket.on('connect_error', (error) => {
        console.error('Connection error:', error);
      });

      // 定期检查连接状态
      const intervalId = setInterval(() => {
        console.log("Current socket state:", socket.connected);
      }, 5000);

      // 检查 socket 的初始状态
      console.log("Initial socket connected state:", socket.connected);

      return () => {
        clearInterval(intervalId);
        socket.off('connect');
        socket.off('disconnect');
        socket.off('error');
        socket.off('connect_error');
        socket.off('message');
        socket.off('user_list');
      };
    } else {
      console.log("Socket is null, event listeners not set up");
    }
  }, [socket]);

  useEffect(() => {
    // 初始化或更新图片列表
    const imgList = messages.map(msg => msg.image).filter(img => img);
    setImages(imgList);
  }, [messages]);

  const generateInviteLink = useCallback(async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/invite/generate`, 
        { room_id: roomId },
        { 
          headers: {
            'Session-Id': localStorage.getItem('session_id')
          },
          withCredentials: true 
        }
      );
      setInviteLink(response.data.invite_link);
      setShowInviteLink(true);
    } catch (error) {
      console.error('Error generating invite link:', error);
    }
  }, [roomId]);

  const copyInviteLink = useCallback(() => {
    navigator.clipboard.writeText(inviteLink).then(() => {
      alert(t('Invite link copied to clipboard!'));
    }).catch(err => {
      console.error('Failed to copy invite link: ', err);
    });
  }, [inviteLink, t]);

  const handleLogout = useCallback(() => {
    axios.post(`${process.env.REACT_APP_API_URL}/logout`, {}, { withCredentials: true })
      .then(() => {
        localStorage.removeItem('session_id');
        localStorage.removeItem(`auth_${roomId}`);
        localStorage.removeItem(`roomPassword_${roomId}`);
        localStorage.removeItem(`userPassword_${roomId}`);
        navigate('/login');
      })
      .catch(error => {
        console.error('Logout failed:', error);
      });
  }, [navigate, roomId]);

  const fetchUsername = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/get_username`, { 
        headers: {
          'Session-Id': localStorage.getItem('session_id')
        },
        withCredentials: true });
      if (response.data && response.data.username) {
        setUsername(response.data.username);
      } else {
        console.error('Username not found in response:', response);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleLogout();
      } else {
        console.error('Error fetching username:', error);
      }
    }
  }, [handleLogout]);

  const renderMessageContent = (msg) => {
    if (msg.username === 'System') {
      // 对系统消息进行特殊处理
      const parts = msg.content.split(/(\{\{.*?\}\})/);
      return (
        <div className="text-lg font-bold">
          {parts.map((part, index) => {
            if (part.startsWith('{{') && part.endsWith('}}')) {
              // 翻译双花括号内的内容
              return t(part.slice(2, -2).trim()) + ' ';
            }
            return part;
          })}
        </div>
      );
    } else if (isImageUrl(msg.content)) {
      return (
        <img 
          src={`${process.env.REACT_APP_BASE_URL}/${msg.content}`} 
          alt="Chat image" 
          className="mt-2 max-w-xs h-auto rounded-lg shadow-md" 
        />
      );
    } else {
      return <div className="text-lg">{msg.content}</div>;
    }
  };

  const fetchMessages = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/messages/${roomId}`, { 
        headers: {
          'Session-Id': localStorage.getItem('session_id')
        },
        withCredentials: true });
      setMessages(response.data.messages);
      scrollToBottom();
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  }, [roomId]);

  const fetchRoomName = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/rooms/${roomId}/name`, { 
        headers: {
          'Session-Id': localStorage.getItem('session_id')
        },
        withCredentials: true
      });

      if (response.data && response.data.room_name) {
        setRoomName(response.data.room_name);
      } else {
        console.error('Room name not found in response:', response);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleLogout();
      } else {
        console.error('Error fetching room name:', error);
      }
    }
  }, [roomId, handleLogout]);

  const authenticateRoom = useCallback(async (roomPassword, userPassword) => {
    console.log("Attempting to authenticate room");
    setError('');
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/room/authenticate`, {
        room_id: roomId,
        room_password: roomPassword,
        user_password: userPassword
      }, { 
        headers: {
          'Session-Id': localStorage.getItem('session_id')
        },
        withCredentials: true
      });

      console.log("Room authentication response:", response.data);

      if (response.data && response.data.authenticated) {
        console.log('Room authenticated successfully');
        setIsAuthenticated(true);
        localStorage.setItem(`auth_${roomId}`, 'true');
        localStorage.setItem(`roomPassword_${roomId}`, roomPassword);
        localStorage.setItem(`userPassword_${roomId}`, userPassword);
        if (response.data.status === 'normal') {
          fetchMessages();
          fetchRoomName();
          fetchUsername();
          if (socket) {
            socket.emit('join', { room_id: roomId, session_id: localStorage.getItem('session_id') });
          }
        } else if (response.data.status === 'fake') {
          setMessages([]);
          setOnlineUsers([username]);
        }
      } else {
        console.error('Error authenticating room:', error);
        setError(t('Invalid room or user password.'));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleLogout();
      } else {
        console.error('Error authenticating room:', error);
        setError(t('Error authenticating room.'));
      }
    }
  }, [handleLogout, fetchMessages, fetchRoomName, fetchUsername, roomId, t, username, socket]);

  useEffect(() => {
    const storedRoomPassword = localStorage.getItem(`roomPassword_${roomId}`);
    const storedUserPassword = localStorage.getItem(`userPassword_${roomId}`);
    if (storedRoomPassword && storedUserPassword) {
      setRoomPassword(storedRoomPassword);
      setUserPassword(storedUserPassword);
      authenticateRoom(storedRoomPassword, storedUserPassword);
    }
  }, [roomId, authenticateRoom]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleEmojiClick = (emojiData, event) => {
    setMessage((prevMessage) => prevMessage + emojiData.emoji);
    setShowEmojiPicker(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Send button clicked L252"); // 调试日志
    if (message.trim() && socket && socket.connected) {
    // if (message.trim() && socket) {
        console.log("Attempting to send message:", message);
        console.log("Socket connected:", socket.connected);
        // const timestamp = moment().toISOString();
        const sessionId = localStorage.getItem('session_id');
        console.log("chatroom handleSubmit L251 Socket state:", socket); // 检查 socket 对象状态
        console.log("chatroom handleSubmit L252 Session ID:", sessionId); // 检查 session_id

        socket.emit('message', {
          room_id: roomId,
          content: message,
          username: username,
          timestamp: new Date().toISOString(),
          session_id: sessionId
        });
        setMessage('');
    } else {
        // console.log("Message is empty or socket is not connected"); // 调试日志
        console.log("Cannot send message. Socket connected:", socket ? socket.connected : "No socket");
        if (!message.trim()) console.log("- Message is empty");
        if (!socket) console.log("- Socket is null");
        if (socket && !socket.connected) console.log("- Socket is not connected");
        console.log("Message:", message);
        console.log("Socket:", socket);
    }
};
 
  
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
    }
  };

  const handleDeleteImage = useCallback(async (messageId) => {
  try {
    // 向服务器发送删除请求
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/messages/${roomId}/${messageId}`, {
      headers: {
        'Session-Id': localStorage.getItem('session_id')
      },
      withCredentials: true
    });

    if (response.data.success) {
      // 如果删除成功，更新本地消息列表
      setMessages(prevMessages => prevMessages.filter(msg => msg.id !== messageId));
      console.log('图片消息已成功删除');
    } else {
      console.error('删除图片消息失败:', response.data.message);
    }
  } catch (error) {
    console.error('删除图片消息时发生错误:', error);
    // 可以在这里添加错误处理逻辑，比如显示一个错误提示给用户
  }
}, [roomId]);


  const handleImageSend = () => {
    if (imageFile && socket) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result;
        const timestamp = moment().toISOString();
        const sessionId = localStorage.getItem('session_id');
        socket.emit('image', { room_id: roomId, image: base64Data, username, timestamp, session_id: sessionId });
        setImageFile(null);
      };
      reader.readAsDataURL(imageFile);
    }
  };

  const handleImageDoubleClick = (imageIndex) => {
    setLightboxIndex(imageIndex);
    setLightboxOpen(true);
  };


  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-center text-gray-900">{t('Enter Room and User Password')}</h2>
          {error && <p className="text-red-500 text-center">{error}</p>}
          <form onSubmit={(e) => { e.preventDefault(); authenticateRoom(roomPassword, userPassword); }} className="space-y-6">
            <div>
              <label htmlFor="roomPassword" className="block text-sm font-medium text-gray-700">{t('Room Password')}</label>
              <input
                type="password"
                id="roomPassword"
                value={roomPassword}
                onChange={(e) => setRoomPassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                placeholder={t('Room Password')}
                required
                autoFocus
              />
            </div>
            <div>
              <label htmlFor="userPassword" className="block text-sm font-medium text-gray-700">{t('User Password')}</label>
              <input
                type="password"
                id="userPassword"
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                placeholder={t('User Password')}
                required
              />
            </div>
            <div>
              <button
                type="submit"
                className="w-full px-4 py-2 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {t('Enter Room')}
              </button>
            </div>
            <div>
              <button
                onClick={() => navigate('/user_panel')}
                className="w-full px-4 py-2 mt-4 text-white bg-gray-600 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                {t('User Panel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
  <div className="flex flex-col h-screen">
    <div className="flex justify-between items-center p-4 bg-gray-800 text-white">
      <h1 className="text-xl">{t('Chat Room')}: {roomName}</h1>
      <div className="flex items-center space-x-2">
        <button
          onClick={generateInviteLink}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {t('Invite')}
        </button>
        {showInviteLink && (
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={inviteLink}
              readOnly
              className="bg-gray-700 text-white px-2 py-1 rounded"
            />
            <button
              onClick={copyInviteLink}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded"
            >
              {t('Copy')}
            </button>
          </div>
        )}
        <button
          onClick={() => {
            if (socket) {
              socket.emit('leave', { room_id: roomId });
            }
            window.location.href = '/user_panel';
          }}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          {t('Leave')}
        </button>
      </div>
    </div>
    
    <div className="flex flex-1 overflow-hidden">
      <div className="w-1/4 bg-gray-200 p-4">
        <h2 className="text-lg font-bold mb-4">{t('Online Users')}</h2>
        <ul>
          {onlineUsers.map((user, index) => (
            <li key={index} className="p-2 border-b border-gray-300">
              {user}
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col w-3/4 p-4 overflow-y-auto">
        <div className="flex-1 space-y-4">
          {messages.length > 0 ? (
            messages.map((msg, index) => (
    <div
      key={index}
      className={`flex ${msg.username === username ? 'justify-end' : 'justify-start'}`}
    >
      <div className={`p-2 rounded-lg ${
                  msg.username === 'System' 
                    ? 'bg-yellow-200 text-center w-full' 
                    : msg.username === username 
                      ? 'bg-green-200' 
                      : 'bg-gray-200'
      }`}>
        <div className="text-sm text-gray-600">
          {t('User')}【{msg.username}】 {moment.utc(msg.timestamp).tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss')}
        </div>
        {renderMessageContent(msg)}
      </div>
    </div>
  ))
) : (
  <div className="text-center text-gray-500">{t('No messages')}</div>
)} 
          <div ref={messagesEndRef} />
        </div>
      </div>
    </div>
        <Lightbox
          open={lightboxOpen}
          close={() => setLightboxOpen(false)}
          index={lightboxIndex}
          slides={images.map(src => ({ src }))}
        />

    <form onSubmit={handleSubmit} className="flex p-4 bg-gray-800 relative">
      <span className="text-white p-2 bg-gray-600 rounded-l-lg">{username}</span>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="flex-1 p-2 focus:outline-none"
        placeholder={t('Type your message here...')}
      />
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        className="hidden"
        id="image-upload"
      />
      <label htmlFor="image-upload" className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 cursor-pointer">
        📷
      </label>
      {imageFile && (
        <button 
          onClick={handleImageSend} 
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4"
        >
          Send Image
        </button>
      )}
      <button
        type="button"
        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
        className="bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-2 px-4"
      >
        😊
      </button>
      {showEmojiPicker && (
        <div className="absolute bottom-16 right-8">
          <EmojiPicker onEmojiClick={handleEmojiClick} pickerStyle={{ position: 'absolute', bottom: '50px', right: '10px' }} />
        </div>
      )}
      <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r-lg">
        {t('Send')}
      </button>
    </form>
  </div>
  );
};

export default ChatRoom;
