// src/components/WebSocketClient.js
import { io } from 'socket.io-client';

// Use the REACT_APP_API_URL from the environment variables
const serverWsUrl = process.env.REACT_APP_API_URL.replace('/api', '');

// 在 WebSocket 初始化前打印出服务器的 URL
console.log('L8 WebSocket Server URL:', serverWsUrl);

// Function to initialize WebSocket connection
const initializeSocket = () => {
  // Check if a valid session_id is present in localStorage
  const sessionId = localStorage.getItem('session_id');
  console.log('websocketclient Session ID:', sessionId);

  // If session_id is not present, do not establish WebSocket connection
  if (!sessionId) {
    console.log('No session ID found. WebSocket connection not established.');
    return null;
  }

  const socket = io(serverWsUrl, {
    withCredentials: true,
    transports: ['websocket', 'polling'],
    query: {
      session_id: sessionId
    },
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    timeout: 10000
  });

  socket.on('connect', () => {
    console.log('WebSocketClient L32 Connected to WebSocket server');

    // 在连接成功后立即发送测试事件
    socket.emit('test_event', { test: 'data' });
  });

  socket.on('disconnect', (reason) => {
    console.log('E39 WebSocketClient Disconnected from WebSocket server:', reason);
    if (reason === 'io server disconnect') {
      // The disconnection was initiated by the server, we need to reconnect manually
      socket.connect();
    }
  });

  socket.on('connect_error', (error) => {
    console.error('E45 Connection error:', error);
    console.log('E46 Detailed error information:', error);
  });

  socket.on('reconnect_attempt', (attempt) => {
    console.log(`Reconnect attempt ${attempt}`);
  });

  socket.on('reconnect_failed', () => {
    console.error('Reconnect failed');
    // 可以在这里添加一些用户通知逻辑
  });

  socket.on('reconnect', (attemptNumber) => {
    console.log(`Reconnected on attempt ${attemptNumber}`);
    // 可以在这里添加重新加入房间的逻辑
  });

  socket.on('error', (error) => {
    console.error('Socket error:', error);
  });

  return socket;
};

export default initializeSocket;