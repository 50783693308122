// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import UserPanel from './components/UserPanel';
import ChatRoom from './components/ChatRoom';
import BuyRoom from './components/BuyRoom';
import ActiveRooms from './components/ActiveRooms';
import RoomDetail from './components/RoomDetail';
import InactiveRooms from './components/InactiveRooms';
import SearchRoom from './components/SearchRoom';
import './i18n'; // Make sure i18n is imported to initialize translations
import axios from 'axios';

// 在这里配置 Axios 拦截器
axios.interceptors.request.use(
  config => {
    const sessionId = localStorage.getItem('session_id');
    if (sessionId) {
      config.headers['Session-Id'] = sessionId;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/user_panel" element={<UserPanel />} />
        <Route path="/chat_room/:roomId" element={<ChatRoom />} />
        <Route path="/buy_room" element={<BuyRoom />} />
        <Route path="/active_rooms" element={<ActiveRooms />} />
        <Route path="/inactive_rooms" element={<InactiveRooms />} />
        <Route path="/room_detail/:roomId" element={<RoomDetail />} />
        <Route path="/search_room" element={<SearchRoom />} />
        <Route path="/" element={<Login />} /> {/* Redirect to login by default */}
      </Routes>
    </Router>
  );
};

export default App;
