// src/components/RoomDetail.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RoomDetail = () => {
  const { t } = useTranslation();
  const { roomId } = useParams();
  const [roomName, setRoomName] = useState('');
  const [roomPassword, setRoomPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoomDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/rooms/${roomId}`, { withCredentials: true });
        if (response.data) {
          setRoomName(response.data.room_name);
        }
      } catch (error) {
        console.error('Error fetching room details:', error);
        setError(t('Error fetching room details'));
      }
    };

    fetchRoomDetails();
  }, [roomId, t]);

  const handleSave = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/room/update`, { room_id: roomId, room_name: roomName, room_password: roomPassword }, { withCredentials: true });
      alert(t('Room updated successfully!'));
      navigate('/active_rooms');
    } catch (error) {
      console.error('Error updating room:', error);
      setError(t('Error updating room'));
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">{t('Room Details')}</h1>
      {error && <p className="text-red-500">{error}</p>}
      <div className="space-y-4">
        <div>
          <label htmlFor="roomName" className="block text-sm font-medium text-gray-700">{t('Room Name')}</label>
          <input
            type="text"
            id="roomName"
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label htmlFor="roomPassword" className="block text-sm font-medium text-gray-700">{t('Room Password')}</label>
          <input
            type="password"
            id="roomPassword"
            value={roomPassword}
            onChange={(e) => setRoomPassword(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <button
            onClick={handleSave}
            className="w-full px-4 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {t('Save')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoomDetail;
