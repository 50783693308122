// src/components/Login.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // 清除所有与聊天室相关的本地存储数据
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith('roomPassword_') || key.startsWith('userPassword_') || key.startsWith('auth_')) {
        localStorage.removeItem(key);
      }
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setError('');

    // const loginUrl = `/api/login`;
    const loginUrl = `${process.env.REACT_APP_API_URL}/login`;
    console.log("loginUrl:", loginUrl);

    try {
      const response = await axios.post(loginUrl, { username, password }, { withCredentials: true });
      if (response.status === 200 && response.data.session_id) {
        localStorage.setItem('session_id', response.data.session_id);
        console.log('L36 Login successful', response.data);
        
        // 获取之前尝试访问的页面，如果没有则默认到用户面板
        const from = location.state?.from || '/user_panel';
        navigate(from, { replace: true });
      }
    } catch (error) {
      console.error('Login failed:', error);
      setError(t('Login failed. Please check your credentials and try again.'));
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-center text-gray-900">{t('Login')}</h2>
        {error && <p className="text-red-500 text-center">{error}</p>}
        <form onSubmit={handleSubmit} className="space-y-6" autoComplete="off">
          <div>
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">{t('Username')}</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              placeholder={t('Username')}
              required
              autoComplete="off"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">{t('Password')}</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              placeholder={t('Password')}
              required
              autoComplete="off"
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full px-4 py-2 text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {t('Login')}
            </button>
          </div>
        </form>
        <div>
          <button
            onClick={() => navigate('/register')}
            className="w-full px-4 py-2 mt-4 text-white bg-gray-600 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            {t('Register')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
