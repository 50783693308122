// src/components/InactiveRooms.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const InactiveRooms = () => {
  const { t } = useTranslation();
  const [rooms, setRooms] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/inactive_rooms`, { withCredentials: true });
        if (response.status === 200) {
          setRooms(response.data.rooms);
        }
      } catch (error) {
        console.error('Error fetching inactive rooms:', error);
        setError(t('Error fetching inactive rooms'));
      }
    };

    fetchRooms();
  }, [t]);

  const handleActivateRoom = async (roomId, roomName, roomPassword) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/room/activate`,
        { room_id: roomId, room_name: roomName, room_password: roomPassword },
        { withCredentials: true }
      );
      if (response.status === 200) {
        setRooms(rooms.filter(room => room.room_id !== roomId));
        alert(t('Room activated successfully!'));
      }
    } catch (error) {
      console.error('Error activating room:', error);
      setError(t('Error activating room'));
    }
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <button
          onClick={() => navigate('/user_panel')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {t('User Panel')}
        </button>
        <button
          onClick={() => navigate('/active_rooms')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
        >
          {t('Active Rooms')}
        </button>
        <button
          onClick={() => navigate('/inactive_rooms')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
        >
          {t('Inactive Rooms')}
        </button>
      </div>

      <h1 className="text-2xl font-bold mb-4">{t('Inactive Rooms')}</h1>
      {error && <p className="text-red-500">{error}</p>}
      <div className="space-y-4">
        {rooms.map((room, index) => (
          <div key={index} className="p-4 border rounded">
            <h2 className="text-xl font-semibold">{t('Room')} {index + 1}: {room.room_name || t('Unnamed')}</h2>
            <div>
              <label>{t('Room Name')}</label>
              <input
                type="text"
                placeholder={t('Room Name')}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                onChange={(e) => room.room_name = e.target.value}
              />
            </div>
            <div>
              <label>{t('Room Password')}</label>
              <input
                type="password"
                placeholder={t('Room Password')}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                onChange={(e) => room.room_password = e.target.value}
              />
            </div>
            <button
              onClick={() => handleActivateRoom(room.room_id, room.room_name, room.room_password)}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
            >
              {t('Activate Room')}
            </button>
          </div>
        ))}
      </div>
      <div>
        <button
          onClick={() => navigate('/user_panel')}
          className="w-full px-4 py-2 mt-4 text-white bg-gray-600 rounded-lg hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          {t('User Panel')}
        </button>
      </div>
    </div>
  );
};

export default InactiveRooms;
