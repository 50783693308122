// src/components/UserPanel.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const UserPanel = () => {
  const { t } = useTranslation();
  const [rooms, setRooms] = useState([]);
  const [totalRooms, setTotalRooms] = useState(0);
  const [activeRooms, setActiveRooms] = useState([]);
  const [inactiveRooms, setInactiveRooms] = useState([]);
  const [error, setError] = useState('');
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRooms = async () => {
      const userPanelUrl = `${process.env.REACT_APP_API_URL}/user_panel`;
      console.log("User Panel URL:", userPanelUrl);
      try {
        const response = await axios.get(userPanelUrl, { 
          headers: {
            'Session-Id': localStorage.getItem('session_id')
          },
          withCredentials: true });
        console.log('User panel response:', response);
        if (response.status === 200) {
          const allRooms = response.data.rooms;
          setRooms(allRooms);
          setTotalRooms(allRooms.length);
          setActiveRooms(allRooms.filter(room => room.status === 1));
          setInactiveRooms(allRooms.filter(room => room.status === 0));
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('session_id');
          navigate('/login', { state: { message: 'Your session has expired. Please log in again.' } });
        } else {
          console.error('E40 Error fetching user panel:', error);
          setError('Error fetching user panel');
        }
      }
    };

    const fetchUsername = async () => {
      const usernameUrl = `${process.env.REACT_APP_API_URL}/get_username`;
      console.log("Username URL:", usernameUrl);
      try {
        const response = await axios.get(usernameUrl, { withCredentials: true });
        if (response.data && response.data.username) {
          setUsername(response.data.username);
        } else {
          console.error('Username not found in response:', response);
        }
      } catch (error) {
        console.error('Error fetching username:', error);
      }
    };

    fetchRooms();
    fetchUsername();
  }, [navigate]);

  const handleEnterRoom = (roomId) => {
    navigate(`/chat_room/${roomId}`);
  };

  const handleLogout = async () => {
    const logoutUrl = `${process.env.REACT_APP_API_URL}/logout`;
    try {
      await axios.post(logoutUrl, {}, { withCredentials: true });
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };
  
  const handleSearchRoom = () => {
    navigate('/search_room');
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">{t('User Panel')}</h1>
      <p>{t('Hello')}, {username}</p>
      <button
        onClick={() => navigate('/buy_room')}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
      >
        {t('Buy Room')}
      </button>
      <button
          onClick={handleSearchRoom}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {t('Search Room')}
      </button>

      <button
          onClick={() => navigate('/active_rooms')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Active Rooms
        </button>
        <button
          onClick={() => navigate('/inactive_rooms')}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Inactive Rooms
        </button>

      <button
        onClick={handleLogout}
        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mb-4 ml-4"
      >
        {t('Logout')}
      </button>
      {error && <p className="text-red-500">{error}</p>}
      <div>
        <p>{t('Total Rooms')}: {totalRooms}</p>
        <p><Link to="/active_rooms">{t('Active Rooms')}: {activeRooms.length}</Link></p>
        <p><Link to="/inactive_rooms">{t('Inactive Rooms')}: {inactiveRooms.length}</Link></p>
      </div>
      <div className="space-y-4">
        {rooms.map((room, index) => (
          <div key={index} className="p-4 border rounded">
            <h2 className="text-xl font-semibold">{room.room_name}</h2>
            <p>Room ID: {room.room_id}</p>
            <button
              onClick={() => handleEnterRoom(room.room_id)}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Enter Room
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserPanel;
